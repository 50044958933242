.modal--content {
  background-color: #333333;
  padding: 16px;
  border-radius: 4px;position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;

  @include breakpoint(sm) {
    width: 400px;
  }
}

.provider-chooser-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.provider-chooser {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #222;
  border: 1px solid #666;
  cursor: pointer;
  margin: 16px;
  padding: 16px;
  text-align: center;

  & img {
    width: 50px;
    display: block;
  }

  & h5 {
    font-weight: bold;
    font-size: 21px;
    margin: 8px;
  }

  & p {
    margin: 0;
    font-size: 14px;
  }
}