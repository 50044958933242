ol {
  list-style: none;
  counter-reset: custom-counter;
  line-height: 1.8;
  padding: 24px 0 0 24px;
}

ol > li {
  counter-increment: custom-counter;
  position: relative;
  margin-bottom: 1rem;
}

ol > li:before {
  content: counter(custom-counter) ". ";
  font-size: 21px;
  position: absolute;
  top: -7px;
  left: -21px;
}