// Button
.button {
  border: 1px solid $white;
  border-radius: 4px;
  background: transparent;
  color: $white;
  display: inline;
  padding: 8px 92px;
  font-family: 'PoeRedcoatNew', Helvetica, Arial, sans-serif;
  font-size: 20px;
  cursor: pointer;
}