@mixin breakpoint($size) {
  $value: map-get($breakpoints, $size);

  @if $value != null {
    @media (min-width: $value) {
      @content;
    }
  }

  @else {
    @warn "No breakpoint in `#{$breakpoint}`. Double-check breakpoints map in $variables.";
  }
}