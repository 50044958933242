.default-spacing {
  margin: 24px 0;
}

// main wrapper

main.wrapper {
  max-width: 1184px;
  padding: 16px;
  margin: 0 auto;

  @include breakpoint(sm) {
    padding: 0 48px;
  }
}

.section-divider {
  margin-top: 80px;
  padding-bottom: 24px;

  @include breakpoint(sm) {
    margin-top: 120px;
  }

  & img {
    max-width: 100%;
    height: 55px;

    @include breakpoint(md) {
      max-width: 1280px;
    }
  }
}

// Sections
.section-content__narrow {
  margin: 0 auto;
  max-width: 750px;
}

.section-content__wide {
  margin: 0 auto;
  max-width: 1280px;
}

