@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap');
@import "utils/vars";
@import "utils/mixins";

// Globals
@import "globals/globals";
@import "globals/typography";
@import "globals/layout";


// Elements
@import "elements/button";
@import "elements/modal";
@import "elements/list";
@import "elements/team-member";

// Blocks
@import "blocks/header";
@import "blocks/intro";
@import "blocks/characters";
@import "blocks/roadmap";
@import "blocks/team";
@import "blocks/social";
@import "blocks/footer";





