.characters-grid {
  display: flex;
  flex-direction: column;

  @include breakpoint(sm) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.character {
  display: flex;
  margin-bottom: 2rem;
  flex-direction: column;
  align-items: center;

  @include breakpoint(sm) {
    flex-direction: row;
    align-items: initial;
  }
}

.character--image {
  width: 167px;
  flex-basis: 167px;

  @include breakpoint(sm) {
    min-width: 167px;
  }

  & img {
    max-width: 100%;
  }
}

.character--info {
  padding: 0 1rem;
  text-align: center;

  @include breakpoint(sm) {
    padding: 0 2rem;
    text-align: left;
  }

  & p {
    line-height: 2;
  }
}